export const FETCH_OPERATORS = '@revolucharge/FETCH_OPERATORS'
export const PUT_OPERATORS = '@revolucharge/PUT_OPERATORS'
export default ({
  fetch: ({ phone, country }) => ({
    type: FETCH_OPERATORS,
    beneficiary: { phone, country }
  }),
  put: (operators) => ({
    type: PUT_OPERATORS,
    operators
  })
})
