import React from 'react'
import { NamespacesConsumer } from 'react-i18next'

import style from './Style.scss'

export default () => (
  <NamespacesConsumer ns={['terms.licenses']}>
    {t => {
      return (
        <div className={style.wrapper}>
          <section>
            <h1>{t('title')} </h1>
            <h2>{t('graphics')}</h2>
            <p>
              <div><a href='https://www.freepik.com/free-vector/coloured-connectivity-icons_888676.htm'>Design by Titusurya</a></div>
              <div><a href='https://www.freepik.com/free-vector/character-illustration-of-people-with-global-network-concept_3226134.htm'>Design by Rawpixel.com</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/maxim-basinski' title='Maxim Basinski'>Maxim Basinski</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='http://www.freepik.com' title='Freepik'>Freepik</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/smashicons' title='Smashicons'>Smashicons</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/roundicons' title='Roundicons'>Roundicons</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/roundicons' title='Roundicons'>Roundicons</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
              <div>Icons made by <a href='https://www.flaticon.com/authors/chris-veigt' title='Chris Veigt'>Chris Veigt</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a> is licensed by <a href='http://creativecommons.org/licenses/by/3.0/' title='Creative Commons BY 3.0' target='_blank'>CC 3.0 BY</a></div>
            </p>
          </section>
        </div>

      )
    }}
  </NamespacesConsumer>
)
