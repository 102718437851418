import { FETCH_PRODUCTS, PUT_PRODUCTS } from 'actions/products'
export default (
  state = {
    loading: false,
    operator: null,
    data: { products: null, promotions: null, provider: null }
  },
  action
) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return Object.assign({}, state, { loading: true, operator: action.operator })
    case PUT_PRODUCTS:
      return Object.assign(
        {},
        state,
        {
          loading: false,
          data: {
            products: action.products, promotions: action.promotions, provider: action.provider
          }
        })
    default:
      return state
  }
}
