import { APPLY, PUT_REWARD, APPLY_ERROR, CLEAR_REWARD } from 'actions/revoluvip'
export default (
  state = {
    loading: false,
    error: false,
    reward: 0
  },
  action
) => {
  switch (action.type) {
    case APPLY:
      return Object.assign({}, state, { loading: true, reward: 0 })
    case PUT_REWARD:
      return Object.assign(
        {}, state, { loading: false, error: false, reward: action.reward }
      )
    case APPLY_ERROR:
      return Object.assign(
        {},
        state,
        { loading: false, error: true, reward: 0 }
      )
    case CLEAR_REWARD:
      return Object.assign(
        {},
        state,
        { loading: false, error: false, reward: 0 }
      )
    default:
      return state
  }
}
