import { FETCH_COUNTRIES, PUT_COUNTRIES } from 'actions/countries'
export default (
  state = {
    loading: false,
    data: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_COUNTRIES:
      return Object.assign({}, state, { loading: true })
    case PUT_COUNTRIES:
      return Object.assign({}, state, { loading: false, data: action.countries })
    default:
      return state
  }
}
