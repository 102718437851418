import React from 'react'
import { BrowserRouter, Switch, Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { NamespacesConsumer } from 'react-i18next'
import PublicRoute from 'components/main/PublicRoute'
import style from './Main.scss'
import { hot } from 'react-hot-loader'

import currencyActions from 'actions/currencies'
import countryActions from 'actions/countries'
import configActions from 'actions/config'
import promotionsActions from 'actions/promotions'

import Header from 'components/main/header/Header'
import Footer from 'components/main/footer/Footer'

import Home from 'components/home/Home'
import Operator from 'components/operator/Operator'
import Product from 'components/product/Product'
import Checkout from 'components/checkout/Checkout'
import Payment from 'components/payment/Payment'
import PaymentOk from 'components/payment/PaymentOk'
import PaymentKo from 'components/payment/PaymentKo'

import Company from 'components/terms/Company'
import Cookies from 'components/terms/Cookies'
import Legal from 'components/terms/Legal'
import Privacy from 'components/terms/Privacy'
import Licenses from 'components/terms/Licenses'

import CookieBanner from 'react-cookie-banner'

class Main extends React.Component {
  constructor ({ dispatch }) {
    super()
    this.dispatch = dispatch
  }

  componentWillMount () {
    this.dispatch(currencyActions.fetch())
    this.dispatch(configActions.fetch())
    this.dispatch(countryActions.fetch())
    this.dispatch(promotionsActions.fetch())
  }

  render () {
    return (
      <NamespacesConsumer ns={['common']}>
        {t => (
          <BrowserRouter>
            <div className={style.wrapper}>
              <Header />
              <CookieBanner className={style.cookiebanner} message={t('Revolucharge uses cookies to guarantee users the employment of its site features, offering a better purchasing experience and by continuing to browse the site you agree to our use of cookies')} link={<Link to='/cookies'>{t('More on our cookie policy')}</Link>} buttonMessage={t('Ok')} cookie='user-has-accepted-cookies' />
              <div className={style.container}>
                <div className={style.main}>
                  <Switch>
                    <PublicRoute exact path='/' component={Home} />
                    <PublicRoute exact path='/order/operators' component={Operator} />
                    <PublicRoute exact path='/order/products' component={Product} />
                    <PublicRoute exact path='/order/checkout' component={Checkout} />
                    <PublicRoute exact path='/order/payment' component={Payment} />
                    <PublicRoute exact path='/order/payment/ok' component={PaymentOk} />
                    <PublicRoute exact path='/order/payment/ko' component={PaymentKo} />
                    <PublicRoute exact path='/company' component={Company} />
                    <PublicRoute exact path='/cookies' component={Cookies} />
                    <PublicRoute exact path='/legal' component={Legal} />
                    <PublicRoute exact path='/privacy' component={Privacy} />
                    <PublicRoute exact path='/licenses' component={Licenses} />
                    <Redirect to='/' />
                  </Switch>
                </div>
                <Footer />
              </div>
            </div>
          </BrowserRouter>
        )}
      </NamespacesConsumer>
    )
  }
}

export default hot(module)(connect()(Main))
