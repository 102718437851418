import React from 'react'
import style from './Payment.scss'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { NamespacesConsumer } from 'react-i18next'
import paymentActions from 'actions/payment'
import QRCode from 'qrcode.react'
import Spinner from 'react-spinkit'
import revolupayLogoImg from '/../resources/img/home/revolupay-logo.png'
import appStoreImg from '/../resources/img/home/appstore.png'
import playStoreImg from '/../resources/img/home/playstore.png'

class Payment extends React.Component {
  constructor ({ dispatch, history }) {
    super()
    this.dispatch = dispatch
    this.history = history
    this.checkPayment = this.checkPayment.bind(this)
  }

  checkPayment () {
    this.dispatch(paymentActions.check(this.props.order.data))
  }

  componentWillMount () {
    if (!this.props.order.data) {
      this.history.push('/orders/operator')
    }
    this.interval = setInterval(this.checkPayment, 2500)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  render () {
    if (this.props.payment.paid) {
      return (<Redirect to='/order/payment/ok' />)
    }
    const reference = this.props.order.data.reference
    return (
      <NamespacesConsumer ns={['payment']}>
        {t => (
          <div className={style.wrapper}>
            <div className={style.content}>
              <h1>{t('Payment')}</h1>
              <h2>{t('Please scan this code with your Revolupay account and complete the payment')}</h2>
              <div className={style.spinner}>
                <Spinner name='ball-scale-multiple' fadeIn='none' />
              </div>
              <div className={style.payment}>
                <QRCode value={reference} size={600} />
                <a href={`https://www.revolupay.com/pnp?action=complete_order&order_reference=${reference}`}>{t('Pay in app')}</a>
              </div>
              <h3>{t('This page will automatically update as soon as the payment has been completed')}</h3>
              <div className={style.disclosure}>
                <div className={style.wrapper}>
                  <img src={revolupayLogoImg} />
                  <div className={style.downloadImgs}>
                    <a href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' target='_blank'><img src={playStoreImg} /></a>
                    <a href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' target='_blank'><img src={appStoreImg} /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </NamespacesConsumer>
    )
  }
}

export default connect((state) => ({
  order: state.order,
  payment: state.payment
}))(withRouter(Payment))
