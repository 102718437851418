import {
  VERIFY_SELECTION, ADD_ITEM, REMOVE_ITEM, CLEAR_VERIFICATION_ERROR, VERIFICATION_ERROR
} from 'actions/shoppingCart'
export default (
  state = {
    loading: false,
    error: false,
    item: null
  },
  action
) => {
  switch (action.type) {
    case VERIFY_SELECTION:
      return Object.assign({}, state, { loading: true })
    case ADD_ITEM:
      return Object.assign(
        {}, state, { loading: false, error: false, item: action.item }
      )
    case REMOVE_ITEM:
      return Object.assign(
        {},
        state,
        { item: null }
      )
    case CLEAR_VERIFICATION_ERROR:
      return Object.assign({}, state, { error: false })
    case VERIFICATION_ERROR:
      return Object.assign({}, state, { loading: false, error: true })
    default:
      return state
  }
}
