import { call, put, takeLatest } from 'redux-saga/effects'
import revoluvipActions, { APPLY } from 'actions/revoluvip'

export default ({ api }) => {
  function * apply (action) {
    try {
      const reward = yield call(api.applyRevoluvip, action.params)
      yield put(revoluvipActions.putReward(reward))
    } catch (error) {
      console.error(error)
      yield put(revoluvipActions.error())
    }
  }

  return function * watch () {
    yield takeLatest(APPLY, apply)
  }
}
