import React from 'react'
import style from './Payment.scss'
import { Link } from 'react-router-dom'
import { NamespacesConsumer } from 'react-i18next'
import errorImg from '/../resources/img/error.svg'

export default () => (
  <NamespacesConsumer ns={['payment']}>
    {t => (
      <div className={style.wrapper}>
        <div className={style.content}>
          <h1>{t('Payment error')}</h1>
          <h2>{t('Sorry but there was an error during the payment process')}</h2>
          <div className={style.payment}>
            <img src={errorImg} />
          </div>
          <h3>{t('We could not process your payment, please try again')}</h3>
          <Link to='/order/operators'>{t('Try again')}</Link>
        </div>
      </div>
    )}
  </NamespacesConsumer>
)
