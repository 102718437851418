export const FETCH_PROMOTIONS = '@revolucharge/FETCH_PROMOTIONS'
export const PUT_PROMOTIONS = '@revolucharge/PUT_PROMOTIONS'
export default ({
  fetch: () => ({
    type: FETCH_PROMOTIONS
  }),
  put: (response) => ({
    type: PUT_PROMOTIONS,
    promotions: response
  })
})
