import { call, put, takeLatest } from 'redux-saga/effects'
import configActions, { FETCH } from 'actions/config'

export default ({ api }) => {
  function * getConfig () {
    try {
      const config = yield call(api.getConfig)
      yield put(configActions.put(config))
    } catch (error) {
      console.error(error)
      yield put(configActions.put({}))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getConfig)
  }
}
