import React from 'react'
import { NamespacesConsumer, Trans } from 'react-i18next'

import style from './Style.scss'

export default () => (
  <NamespacesConsumer ns={['terms.company']}>
    {t => {
      return (
        <div className={style.wrapper}>
          <section>
            <h1>
              <Trans i18nKey='title'>
                Legal information about RP PAYMENT SERVICES SLU
              </Trans>
            </h1>
            <p>
              <Trans i18nKey='content'>
                The ownership of this website is from RP PAYMENT SERVICES SLU with registered office at calle Vallespir, 19 1 - 08173 Sant cugat del Vallès (Barcelona), registered in the Mercantile Registry of Barcelona.
              </Trans>
            </p>
          </section>
        </div>

      )
    }}
  </NamespacesConsumer>
)
