import { FETCH, PUT_CONFIG } from 'actions/config'
export default (
  state = {
    loading: false,
    data: {}
  },
  action
) => {
  switch (action.type) {
    case FETCH:
      return Object.assign({}, state, { loading: true })
    case PUT_CONFIG:
      return Object.assign({}, state, { loading: false, data: action.config })
    default:
      return state
  }
}
