export const APPLY = '@revolucharge/APPLY_REVOLUVIP'
export const PUT_REWARD = '@revolucharge/PUT_REVOLUVIP_REWARD'
export const CLEAR_REWARD = '@revolucharge/PUT_REVOLUVIP_CLEAR_REWARD'
export const APPLY_ERROR = '@revolucharge/REVOLUVIP_APPLY_ERROR'
export default ({
  apply: ({ email, password }) => ({
    type: APPLY,
    params: {
      email, password
    }
  }),
  putReward: ({ reward }) => ({
    type: PUT_REWARD,
    reward
  }),
  clear: () => ({
    type: CLEAR_REWARD
  }),
  error: () => ({
    type: APPLY_ERROR
  })
})
