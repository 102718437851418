import React from 'react'
export default ({ value, currencyIso }) => (
  <React.Fragment>
    <span>{new Intl.NumberFormat(
      ['es-ES', 'en-GB', 'en-US'],
      { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }
    ).format(value)}</span>
    <span>{currencyIso}</span>
  </React.Fragment>
)
