import React from 'react'
import style from './Footer.scss'
import { Link } from 'react-router-dom'
import { NamespacesConsumer } from 'react-i18next'
export default () => (
  <NamespacesConsumer ns={['footer']}>
    {t => (
      <div className={style.wrapper}>
        <div className={style.upper}>
          <ul>
            <li><Link to='/legal'>{t('Terms and conditions')}</Link></li>
            <li className={style.spacer}>|</li>
            <li><Link to='/company'>{t('The company')}</Link></li>
            <li className={style.spacer}>|</li>
            <li><Link to='/privacy'>{t('Privacy notice')}</Link></li>
            <li className={style.spacer}>|</li>
            <li><Link to='/cookies'>{t('Cookie policy')}</Link></li>
            <li className={style.spacer}>|</li>
            <li><Link to='/licenses'>{t('Licenses')}</Link></li>
            <li className={style.spacer}>|</li>
            <li><a href='mailto:contact@revolucharge.com'>contact@revolucharge.com</a></li>
          </ul>
        </div>
        <div className={style.lower}>
          <span>RevoluCHARGE is a division of <a href='http://www.revolupay.es/' _target='blank'>RP PAYMENT SERVICES SLU</a> wholly owned subsidiary of <a href='https://www.revolugroup.com' target='_blank'>RevoluGROUP Canada Inc.</a> a publicly traded company on the Toronto Stock Exchange - <a href='https://money.tmx.com/en/quote/REVO' _target='blank'>REVO</a></span>
        </div>
      </div>
    )}
  </NamespacesConsumer>
)
