import { call, put, takeLatest } from 'redux-saga/effects'
import orderActions, { PLACE_ORDER } from 'actions/order'

export default ({ api }) => {
  function * placeOrder (action) {
    try {
      const order = yield call(api.placeOrder, action.params)
      yield put(orderActions.put(order))
    } catch (error) {
      console.error(error)
      yield put(orderActions.error(error))
    }
  }

  return function * watch () {
    yield takeLatest(PLACE_ORDER, placeOrder)
  }
}
