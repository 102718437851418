export const PLACE_ORDER = '@revolucharge/CREATE_ORDER'
export const PUT_ORDER = '@revolucharge/PUT_ORDER'
export const CLEAR_ORDER = '@revolucharge/CLEAR_ORDER'
export const PLACE_ORDER_ERROR = '@revolucharge/PLACE_ORDER_ERROR'
export const CLEAR_ORDER_ERROR = '@revolucharge/CLEAR_ORDER_ERROR'
export default ({
  place: ({ productId, sendValue, locale, phone, paymentType, user, revoluvip }) => ({
    type: PLACE_ORDER,
    params: {
      productId,
      sendValue,
      locale,
      phone,
      paymentType,
      user,
      revoluvip
    }
  }),
  put: ({ orderId, paymentId, reference, amount, finishAt }) => ({
    type: PUT_ORDER,
    order: { orderId, paymentId, reference, amount, finishAt }
  }),
  clear: () => ({
    type: CLEAR_ORDER
  }),
  error: () => ({
    type: PLACE_ORDER_ERROR
  }),
  clearError: () => ({
    type: CLEAR_ORDER_ERROR
  })
})
