import { call, put, takeLatest } from 'redux-saga/effects'
import productActions, { FETCH_PRODUCTS } from 'actions/products'

export default ({ api }) => {
  function * fetchProducts (action) {
    try {
      const products = yield call(api.getProducts, { operatorId: action.operator.id })
      yield put(productActions.put(products))
    } catch (error) {
      console.error(error)
      yield put(productActions.put([]))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH_PRODUCTS, fetchProducts)
  }
}
