import { FETCH_PROMOTIONS, PUT_PROMOTIONS } from 'actions/promotions'
export default (
  state = {
    loading: false,
    data: { promotions: null }
  },
  action
) => {
  switch (action.type) {
    case FETCH_PROMOTIONS:
      return Object.assign({}, state, { loading: true })
    case PUT_PROMOTIONS:
      return Object.assign({}, state, { loading: false, data: { promotions: action.promotions } })
    default:
      return state
  }
}
