import { call, put, takeLatest } from 'redux-saga/effects'
import countryActions, { FETCH_COUNTRIES } from 'actions/countries'

export default ({ api }) => {
  function * fetchCountries () {
    try {
      const countries = yield call(api.getCountries)
      yield put(countryActions.put(countries))
    } catch (error) {
      console.error(error)
      yield put(countryActions.put([]))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH_COUNTRIES, fetchCountries)
  }
}
