import { call, put, takeLatest } from 'redux-saga/effects'
import promotionsActions, { FETCH_PROMOTIONS } from 'actions/promotions'

export default ({ api }) => {
  function * fetchPromotions () {
    try {
      const promotions = yield call(api.getPromotions)
      yield put(promotionsActions.put(promotions))
    } catch (error) {
      console.error(error)
      yield put(promotionsActions.put([]))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH_PROMOTIONS, fetchPromotions)
  }
}
