import { call, put, takeLatest } from 'redux-saga/effects'
import paymentActions, { CHECK_PAYMENT } from 'actions/payment'

export default ({ api }) => {
  function * checkPayment (action) {
    try {
      const paymentStatus = yield call(api.checkPayment, action.params)
      if (paymentStatus.paid) {
        yield put(paymentActions.markPaid())
      }
    } catch (error) {
      console.error(error)
    }
  }

  return function * watch () {
    yield takeLatest(CHECK_PAYMENT, checkPayment)
  }
}
