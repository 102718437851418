import React from 'react'
import style from './Operator.scss'
import Searchbar from 'components/shared/Searchbar'
import Item from './Item'
import { NamespacesConsumer, Trans } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import operatorActions from 'actions/operators'
import productActions from 'actions/products'
import paymentActions from 'actions/payment'
import orderActions from 'actions/order'
import cartActions from 'actions/shoppingCart'

const Operator = ({ dispatch, history, countries, operators }) => (
  <NamespacesConsumer ns={['common']}>
    {t => (
      <div className={style.wrapper}>
        <div className={style.content}>
          <h1>{t('Enter the phone number you want to top-up')}</h1>
          <Searchbar
            currentBeneficiary={operators.beneficiary}
            countries={countries.data}
            action={({ phone, country }) => {
              dispatch(operatorActions.fetch({ phone, country }))
            }}
          />
          {operators.data === null &&
            <React.Fragment>
              <h1>{t('Recharge prepaid mobile phones in moments')}</h1>
              <Trans i18nKey='operators-intro'>
                We've partnered with over 500 operators across 140 countries, so topping-up worldwide with Revolucharge has never been easier, faster or more secure.
              </Trans>
            </React.Fragment>
          }
          {operators.data &&
            <React.Fragment>
              <h1>{t('Select operator')}</h1>
              <div className={style.results}>
                {
                  operators.data.map(operator => (
                    <Item key={operator.id} operator={operator} onClick={() => {
                      dispatch(paymentActions.clear())
                      dispatch(orderActions.clear())
                      dispatch(cartActions.remove())
                      dispatch(productActions.fetch({ operator }))
                      history.push('/order/products')
                    }} />
                  ))
                }
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    )}
  </NamespacesConsumer>
)

export default connect(state => ({
  countries: state.countries,
  operators: state.operators
}))(withRouter(Operator))
