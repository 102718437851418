import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import style from './Searchbar.scss'
import classNames from 'classnames'

export default class Searchbar extends React.Component {
  constructor ({ currentBeneficiary, countries }) {
    super()
    this.submit = this.submit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    let defaultValues
    if (!currentBeneficiary) {
      defaultValues = { countryId: 0, phone: '' }
    } else {
      defaultValues = { countryId: currentBeneficiary.country.id, phone: currentBeneficiary.phone }
    }
    const filteredCountries = countries.filter(
      country => country.id === parseInt(defaultValues.countryId)
    )
    let selectedCountry
    if (filteredCountries.length > 0) {
      selectedCountry = filteredCountries[0]
    }
    this.state = {
      invalid: false,
      currentCountry: selectedCountry,
      countryId: defaultValues.countryId,
      phone: defaultValues.phone
    }
  }

  handleChange (e) {
    const value = e.target.value
    const field = e.target.name
    if (field === 'phone') {
      if (this.state.currentCountry) {
        const actualLength = value.length + this.state.currentCountry.prefix.length
        let maximumLength = this.state.currentCountry.maximumLength
        if (maximumLength === null) {
          maximumLength = 20
        }
        if (actualLength <= maximumLength) {
          this.setState({ invalid: false, [field]: value })
        }
      } else {
        this.setState({ invalid: false, [field]: value })
      }
    } else {
      const country = this.props.countries.filter(
        country => country.id === parseInt(value)
      )[0]
      this.setState({ invalid: false, [field]: value, currentCountry: country })
    }
  }

  submit () {
    if (!this.state.countryId || !this.state.phone) {
      this.setState({ invalid: true })
      setTimeout(() => {
        this.setState({ invalid: false })
      }, 500)
    } else {
      const country = this.props.countries.filter(country => country.id === parseInt(this.state.countryId))[0]
      if (country.minimumLnegth === null) {
        country.minimumLnegth = 0
      }
      if (country.maximumLength === null) {
        country.maximumLength = 20
      }
      const actualLength = this.state.phone.length + country.prefix.length
      if (actualLength < country.minimumLnegth || actualLength > country.maximumLength) {
        this.setState({ invalid: true })
        setTimeout(() => {
          this.setState({ invalid: false })
        }, 500)
      } else {
        this.props.action({ country, phone: this.state.phone })
      }
    }
  }

  render () {
    const className = classNames(
      style.searchbox,
      { [style.invalid]: this.state.invalid, [style.disabled]: this.props.disabled }
    )
    return (
      <NamespacesConsumer ns={['common']}>
        {t => (
          <div className={className}>
            <div className={style.inputs}>
              <select
                value={this.state.countryId}
                name='countryId'
                onChange={this.handleChange}
                disabled={this.props.disabled}>
                <option value={0} disabled>{t('Country code')}</option>
                {this.props.countries.map(country => (
                  <option key={country.id} value={country.id}>
                    {`+${country.prefix} - ${country.name}`}
                  </option>
                ))}
              </select>
              <input
                value={this.state.phone}
                name='phone'
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.submit()
                  }
                }}
                onBlur={this.submit}
                onChange={this.handleChange}
                placeholder={t('Phone number')}
                type='text'
                disabled={this.props.disabled} />
            </div>
            <button
              disabled={this.props.disabled}
              className={classNames(style.button, { [style.disabled]: this.props.disabled })}
              onClick={this.submit}
            >
              {t('GO')}
            </button>
          </div>
        )}
      </NamespacesConsumer>
    )
  }
}
