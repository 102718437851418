import React from 'react'
import { Route } from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import ga from 'react-ga'

const trackingId = process.env.GA_TRACKING_ID

if (trackingId) {
  ga.initialize(trackingId)
}

const PublicRoute = ({ component: Component, rest }) => (
  <Route {...rest} render={({ props, location }) => {
    if (trackingId) {
      ga.pageview(location.pathname)
    }
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    )
  }} />
)

export default PublicRoute
