import { combineReducers } from 'redux'
import currenciesReducer from 'reducers/currenciesReducer'
import countriesReducer from 'reducers/countriesReducer'
import operatorsReducer from 'reducers/operatorsReducer'
import productsReducer from 'reducers/productsReducer'
import shoppingCardReducer from 'reducers/shoppingCartReducer'
import orderReducer from 'reducers/orderReducer'
import paymentReducer from 'reducers/paymentReducer'
import promotionsReducer from 'reducers/promotionsReducer'
import revoluvipReducer from 'reducers/revoluvipReducer'
import configReducer from 'reducers/configReducer'

export default combineReducers({
  currencies: currenciesReducer,
  countries: countriesReducer,
  operators: operatorsReducer,
  products: productsReducer,
  cart: shoppingCardReducer,
  order: orderReducer,
  payment: paymentReducer,
  promotions: promotionsReducer,
  revoluvip: revoluvipReducer,
  config: configReducer
})
