import React from 'react'
import Main from 'components/main/Main'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import reducer from 'reducers/rootReducer'
import saga from 'sagas/rootSaga'
import i18n from 'i18n'
import Api from 'services/Api'
import { I18nextProvider } from 'react-i18next'

const API_URL = process.env.API_URL

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

let middleware = null
if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(sagaMiddleware)
} else {
  middleware = composeEnhancers(applyMiddleware(sagaMiddleware))
}
const store = createStore(
  reducer,
  middleware
)

const api = Api({ baseUrl: API_URL, fetch })
sagaMiddleware.run(saga({ api }))

const App = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Main />
    </I18nextProvider>
  </Provider>
)

export default App
