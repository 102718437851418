import { MARK_PAYMENT_PAID, CLEAR_PAYMENT } from 'actions/payment'
export default (
  state = {
    paid: false
  },
  action
) => {
  switch (action.type) {
    case MARK_PAYMENT_PAID:
      return Object.assign(
        {}, state, { paid: true }
      )
    case CLEAR_PAYMENT:
      return Object.assign(
        {},
        state,
        { paid: false }
      )
    default:
      return state
  }
}
