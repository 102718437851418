import React from 'react'
import style from './Item.scss'
import CurrencyFormat from 'components/shared/CurrencyFormat'
import { round } from 'services/Functions'
import { NamespacesConsumer } from 'react-i18next'
import classNames from 'classnames'
import Modal from 'react-responsive-modal'
import infoBlackImg from '/../resources/img/info-black.svg'
import infoWhiteImg from '/../resources/img/info-white.svg'
export default ({ product, receiveValue, sendValue, currencies, selected, onClick, shouldShowDetails, showDetails, closeDetails }) => {
  if (shouldShowDetails === null || shouldShowDetails === undefined) {
    shouldShowDetails = false
  }
  const receiveCurrency = currencies[product.receiveCurrencyId]
  let sendCurrency = currencies[product.sendCurrencyId]
  let name = product.name

  if (name.includes(receiveCurrency.iso)) {
    name = null
  }
  const className = classNames(style.result, { [style.selected]: selected })
  return (
    <NamespacesConsumer ns={['common']}>
      {t => (
        <React.Fragment>
          <Modal classNames={{ overlay: style['modal-overlay'] }} open={shouldShowDetails} onClose={closeDetails}>
            <div className={style['modal-body']}>
              <h2>{t('Product description')}</h2>
              <p>{product.description}</p>
            </div>
          </Modal>
          <div className={className} onClick={onClick}>
            <div onClick={showDetails}>
              {product.description && selected &&
              <img className={style.info} src={infoWhiteImg} />
              }
              {product.description && !selected &&
              <img className={style.info} src={infoBlackImg} />
              }
            </div>
            {name &&
              <span className={style.name}>{name}</span>
            }
            <div className={style.value} >
              <CurrencyFormat value={receiveValue} currencyIso={receiveCurrency.iso} />
            </div>
            {sendValue &&
              <div className={style.sendValue} >
                <CurrencyFormat value={round(sendValue / sendCurrency.rate)} currencyIso={'₡CU'} />
              </div>
            }
          </div>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  )
}
