import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'

export const languages = [{ 'en': 'English' }, { 'es': 'Spanish' }]

i18n.use(LanguageDetector)
let options = {
  fallbackLng: 'en',
  interpolation: { escapeValue: false }
}
i18n.use(XHR)
options = {
  ...options,
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json'
  }
}
i18n.init(options)
export default i18n
