export const CHECK_PAYMENT = '@revolucharge/CHECK_PAYMENT'
export const MARK_PAYMENT_PAID = '@revolucharge/PAYMENT_IS_PAID'
export const CLEAR_PAYMENT = '@revolucharge/CLEAR_PAYMENT'
export default ({
  check: ({ paymentId }) => ({
    type: CHECK_PAYMENT,
    params: { paymentId }
  }),
  markPaid: () => ({
    type: MARK_PAYMENT_PAID
  }),
  clear: () => ({
    type: CLEAR_PAYMENT
  })
})
