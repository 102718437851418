import { FETCH_OPERATORS, PUT_OPERATORS } from 'actions/operators'
export default (
  state = {
    loading: false,
    beneficiary: null,
    data: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_OPERATORS:
      return Object.assign({}, state, { loading: true, beneficiary: action.beneficiary })
    case PUT_OPERATORS:
      return Object.assign({}, state, { loading: false, data: action.operators })
    default:
      return state
  }
}
