import React from 'react'
import { NamespacesConsumer, withI18n } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import operatorActions from 'actions/operators'
import Searchbar from 'components/shared/Searchbar'
import style from './Home.scss'

import topupImg from '/../resources/img/home/topup.svg'
import getInTouchImg from '/../resources/img/home/getintouch.svg'
import pickNumberImg from '/../resources/img/home/picknumber.svg'
import revoluchargeImg from '/../resources/img/home/revolucharge.png'
import revoloufinImg from '/../resources/img/home/revolufin.png'
import revolupayImg from '/../resources/img/home/revolupay.png'
import revolutilityImg from '/../resources/img/home/revolutility.png'
import revoluvipImg from '/../resources/img/home/revoluvip.jpg'

import bmobileImg from '/../resources/img/home/operators/bmobile.png'
import claroImg from '/../resources/img/home/operators/claro.png'
// import cubacelImg from '/../resources/img/home/operators/cubacel.png'
import digicelImg from '/../resources/img/home/operators/digicel.png'
import flowImg from '/../resources/img/home/operators/flow.png'
import limeImg from '/../resources/img/home/operators/lime.png'
import movistarImg from '/../resources/img/home/operators/movistar.png'
import orangeImg from '/../resources/img/home/operators/orange.png'
import roshanImg from '/../resources/img/home/operators/roshan.png'
import connectImg from '/../resources/img/home/connect.svg'
import revolupayLogoImg from '/../resources/img/home/revolupay-logo.png'
import revolupayLogoLightImg from '/../resources/img/home/revolupay-logo-light.png'
import appStoreImg from '/../resources/img/home/appstore.png'
import playStoreImg from '/../resources/img/home/playstore.png'

import Modal from 'react-responsive-modal'

class Home extends React.Component {
  constructor ({ i18n }) {
    super()
    this.state = {
      promo: null
    }
    this.i18n = i18n
    this.getLocalizedDingPromotionDescription = this.getLocalizedDingPromotionDescription.bind(this)
    this.getDingPromotionModalBody = this.getDingPromotionModalBody.bind(this)
  }

  getLocalizedDingPromotionDescription (content) {
    const locale = this.i18n.language
    const filteredDescriptions = content.descriptions.filter((e) => e.languageCode === locale)
    if (filteredDescriptions.length > 0) {
      return filteredDescriptions[0]
    } else return content.descriptions[0]
  }

  getDingPromotionModalBody (t) {
    const description = this.getLocalizedDingPromotionDescription(this.state.promo.data)
    return (
      <React.Fragment>
        <p> {description.termsAndConditions} </p>
        <h3>{t('Duration')}</h3>
        <p>{`${new Date(this.state.promo.data.startUtc).toLocaleDateString()} - ${new Date(this.state.promo.data.endUtc).toLocaleDateString()}`}</p>
        <h3>{t('Validity')}</h3>
        <p>{description.bonusValidity}</p>
      </React.Fragment>
    )
  }
  getCysendPromotionModalBody (t) {
    return (
      <React.Fragment>
        <p> {this.state.promo.data.conditions} </p>
        <h3>{t('Duration')}</h3>
        <p>{`${new Date(this.state.promo.data.start).toLocaleDateString()} - ${new Date(this.state.promo.data.end).toLocaleDateString()}`}</p>
      </React.Fragment>
    )
  }

  render () {
    const { history, dispatch, countries, promotions } = this.props
    return (
      <NamespacesConsumer ns={['home', 'common']}>
        {t => (
          <div className={style.wrapper}>
            <Modal open={this.state.promo !== null} onClose={() => {
              this.setState({ promo: null })
            }}>
              <div className={style['modal-body']}>
                <h2>{t('Terms & Conditions')}</h2>
                {this.state.promo &&
                  (() => {
                    if (this.state.promo.provider === 'DING') {
                      return this.getDingPromotionModalBody(t)
                    } else {
                      return this.getCysendPromotionModalBody(t)
                    }
                  })()
                }
              </div>
            </Modal>
            <div className={style.welcome}>
              <h1>{t('Delivering mobile top-up to family and friends worldwide')}</h1>
              <h3>{t('Recharge instantly and stay connected with the people that matter most')}</h3>
              <Searchbar
                countries={countries.data}
                action={({ phone, country }) => {
                  dispatch(operatorActions.fetch({ phone, country }))
                  history.push('/order/operators')
                }}
              />
              <div className={style.disclosure}>
                <div className={style.wrapper}>
                  <img src={revolupayLogoImg} />
                  <div className={style.downloadImgs}>
                    <a href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' target='_blank'><img src={playStoreImg} /></a>
                    <a href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' target='_blank'><img src={appStoreImg} /></a>
                  </div>
                  <h4>{t('EARN_REWARDS', { reward: this.props.config.data.revolupayReward })}</h4>
                </div>
              </div>
            </div>
            <div className={style.disclosure}>
              <div className={style.wrapper}>
                <img src={revolupayLogoLightImg} />
                <div className={style.downloadImgs}>
                  <a href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' target='_blank'><img src={playStoreImg} /></a>
                  <a href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' target='_blank'><img src={appStoreImg} /></a>
                </div>
                <h4>{t('EARN_REWARDS', { reward: this.props.config.data.revolupayReward })}</h4>
              </div>
            </div>
            {promotions.data.promotions && promotions.data.promotions.length > 0 &&
              <div className={style['promo-wrapper']}>
                <h1>{t('Best promotions')}</h1>
                <div className={style.promos}>
                  {promotions.data.promotions.map((promo) => {
                    if (promo.provider.impl === 'DING') {
                      const content = JSON.parse(promo.content)
                      const description = this.getLocalizedDingPromotionDescription(content)
                      return (
                        <div key={promo.id} className={style.promo}>
                          <div className={style.operator}>
                            <img src={`${process.env.ASSETS}/${promo.operator.logo}`} alt='' />
                          </div>
                          <div className={style.description}>
                            <p>{description.headline}</p>
                            <span onClick={() => {
                              this.setState({ promo: { data: content, provider: 'DING' } })
                            }} >{t('Terms & Conditions')}</span>
                          </div>
                        </div>
                      )
                    } else {
                      const content = JSON.parse(promo.content)
                      return (
                        <div key={promo.id} className={style.promo}>
                          <div className={style.operator}>
                            <img src={`${process.env.ASSETS}/${promo.operator.logo}`} alt='' />
                          </div>
                          <div className={style.description}>
                            <p>{content.title}</p>
                            <span onClick={() => {
                              this.setState({ promo: { data: content, provider: 'CYSEND' } })
                            }} >{t('Terms & Conditions')}</span>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            }
            <div className={style.references}>
              <h1>{t('Our services network')}</h1>
              <div className={style.products}>
                <div className={style.product}>
                  <a href='#'>
                    <img src={revoluchargeImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>{t('RevoluCHARGE')}</span>
                    <span className={style.detail}>{t('Mobile topup')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='https://www.revolufin.com' target='_blank'>
                    <img src={revoloufinImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>{t('RevoluFIN')}</span>
                    <span className={style.detail}>{t('Forfaiting')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='https://www.revolupay.es' target='_blank'>
                    <img src={revolupayImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>{t('RevoluPAY')}</span>
                    <span className={style.detail}>{t('Payments')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='http://www.revolutility.com' target='_blank'>
                    <img src={revolutilityImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>{t('RevolUTILITY')}</span>
                    <span className={style.detail}>{t('Invoice payment')}</span>
                  </a>
                </div>
                <div className={style.product}>
                  <a href='http://www.revoluvip.club' target='_blank'>
                    <img src={revoluvipImg} alt='' />
                    <div className={style.overlay} />
                    <span className={style.title}>{t('RevoluVIP')}</span>
                    <span className={style.detail}>{t('Private club')}</span>
                  </a>
                </div>
              </div>
            </div>
            <div className={style.disclaimer}>
              <img className={style.connect} src={connectImg} alt='' />
              <h1>{t('Worldwide top-up to over 500 networks across 140 countries')}</h1>
              <div className={style.providerLogos}>
                <div className={style.logo}>
                  <img src={bmobileImg} alt='' />
                </div>
                <div className={style.logo}>
                  <img src={claroImg} alt='' />
                </div>
                {/* <div className={style.logo}>
                  <img src={cubacelImg} alt='' />
                </div> */}
                <div className={style.logo}>
                  <img src={digicelImg} alt='' />
                </div>
                <div className={style.logo}>
                  <img src={flowImg} alt='' />
                </div>
                <div className={style.logo}>
                  <img src={limeImg} alt='' />
                </div>
                <div className={style.logo}>
                  <img src={movistarImg} alt='' />
                </div>
                <div className={style.logo}>
                  <img src={orangeImg} alt='' />
                </div>
                <div className={style.logo}>
                  <img src={roshanImg} alt='' />
                </div>
              </div>
            </div>
            <div className={style.callToAction}>
              <div className={style.steps}>
                <div className={style.step}>
                  <img src={pickNumberImg} alt={t('Choose a number')} />
                  <span>{t('Choose a number')}</span>
                </div>
                <div className={style.step}>
                  <img src={topupImg} alt={t('Send a top-up')} />
                  <span>{t('Send a top-up')}</span>
                </div>
                <div className={style.step}>
                  <img src={getInTouchImg} alt={t('Get connected')} />
                  <span>{t('Get connected')}</span>
                </div>
              </div>
              <div className={style.button} onClick={() => {
                history.push('/order/operators')
              }}>
                {t('Top-up now')}
              </div>
            </div>
          </div>
        )}
      </NamespacesConsumer>
    )
  }
}
export default connect(state => ({
  countries: state.countries, promotions: state.promotions, config: state.config
}))(withI18n()(withRouter(Home)))
