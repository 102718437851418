export const FETCH_COUNTRIES = '@revolucharge/FETCH_COUNTRIES'
export const PUT_COUNTRIES = '@revolucharge/PUT_COUNTRIES'
export default ({
  fetch: () => ({
    type: FETCH_COUNTRIES
  }),
  put: (countries) => ({
    type: PUT_COUNTRIES,
    countries
  })
})
