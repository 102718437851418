import { call, put, takeLatest } from 'redux-saga/effects'
import operatorActions, { FETCH_OPERATORS } from 'actions/operators'

export default ({ api }) => {
  function * fetchOperators (action) {
    try {
      const operators = yield call(
        api.getOperators,
        { countryId: action.beneficiary.country.id, phone: action.beneficiary.phone }
      )
      yield put(operatorActions.put(operators))
    } catch (error) {
      console.error(error)
      yield put(operatorActions.put([]))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH_OPERATORS, fetchOperators)
  }
}
