import { URLSearchParams } from 'universal-url'
export default ({ baseUrl, fetch }) => ({
  getCurrencies: () => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/currencies`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getCountries: () => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/countries`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getConfig: () => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/config`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getOperators: ({ phone, countryId }) => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/countries/${countryId}/operators?phone=${phone}`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getProducts: ({ operatorId }) => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/operators/${operatorId}/products`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  verifyTransfer: ({ productId, phone, receiveValue }) => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/products/${productId}`,
      {
        method: 'POST',
        body: JSON.stringify({ phone, receiveValue }),
        headers: { 'content-type': 'application/json' }
      }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  placeOrder: ({ productId, sendValue, locale, phone, paymentType, user, revoluvip }) => new Promise((resolve, reject) => {
    if (locale.includes('es')) {
      locale = 'es'
    } else {
      locale = 'en'
    }
    fetch(
      `${baseUrl}/orders`,
      {
        method: 'POST',
        body: JSON.stringify({ productId, sendValue, locale, phone, paymentType, user, revoluvip }),
        headers: { 'content-type': 'application/json' }
      }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  checkPayment: ({ paymentId }) => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/payment/${paymentId}`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  getPromotions: () => new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/promotions`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  }),
  applyRevoluvip: ({ email, password }) => new Promise((resolve, reject) => {
    const params = new URLSearchParams()
    params.append('email', email)
    params.append('password', password)
    fetch(
      `${baseUrl}/revoluvip?${params.toString()}`,
      { method: 'GET' }
    ).then(res => {
      if (res.ok) res.json().then(json => resolve(json))
      else reject(new Error(`Invalid status code:${res.status}`))
    }).catch(err => reject(err))
  })
})
