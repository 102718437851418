import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import style from './Main.scss'

class ErrorBoundary extends Component {
  constructor ({ history }) {
    super()
    this.state = { error: null, errorInfo: null }
    this.history = history
  }

  componentDidCatch (error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    const unlisten = this.history.listen(() => {
      this.setState({ error: null, errorInfo: null })
      unlisten()
    })
  }

  render () {
    if (this.state.errorInfo) {
      return (
        <div className={style.error}>
          <h1>Something went wrong.</h1>
          <Link to='/'>Back to home</Link>
          {/* <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details> */}
        </div>
      )
    }
    return this.props.children
  }
}
export default withRouter(ErrorBoundary)
