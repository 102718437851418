import React from 'react'
import style from './Checkout.scss'
import Product from 'components/product/Item'
import Operator from 'components/operator/Item'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { round } from 'services/Functions'
import { NamespacesConsumer, Trans, withI18n } from 'react-i18next'
import classNames from 'classnames'
import cartActions from 'actions/shoppingCart'
import orderActions from 'actions/order'
import revoluvipActions from 'actions/revoluvip'
import CurrencyFormat from 'components/shared/CurrencyFormat'
import Modal from 'react-responsive-modal'
import Spinner from 'react-spinkit'
// import revolupayLogoImg from '/../resources/img/home/revolupay-logo.png'
// import creditCardImg from '/../resources/img/credit-card.png'
import revoluvipLogoImg from '/../resources/img/revoluvip-logo.png'

const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

class Checkout extends React.Component {
  constructor ({ dispatch, history, i18n }) {
    super()
    this.dispatch = dispatch
    this.history = history
    this.i18n = i18n
    this.state = {
      shouldShowDetails: false,
      paymentMethod: 'REVOLUPAY',
      formTouched: false,
      formErrors: {
      },
      revoluVipFormErrors: {
      },
      userData: {
        name: '',
        surname: '',
        phone: '',
        email: ''
      },
      revoluvip: {
        email: '',
        password: ''
      }
    }
  }

  placeOrder = () => {
    const item = this.props.cart.item
    let action = orderActions.place({
      productId: item.product.id,
      sendValue: item.sendValue,
      locale: this.i18n.language,
      phone: item.beneficiary.phone,
      paymentType: this.state.paymentMethod,
      user: this.state.userData
    })
    if (this.state.paymentMethod === 'REVOLUPAY' && this.props.revoluvip.reward > 0) {
      action = orderActions.place({
        productId: item.product.id,
        sendValue: item.sendValue,
        locale: this.i18n.language,
        phone: item.beneficiary.phone,
        paymentType: this.state.paymentMethod,
        user: this.state.userData,
        revoluvip: this.state.revoluvip
      })
    }

    if (this.state.paymentMethod === 'PAYLANDS') {
      if (this.validateForm()) {
        this.dispatch(action)
      }
    } else {
      this.dispatch(action)
    }
  }

  validateForm = () => {
    const formErrors = {}
    const userData = this.state.userData
    let formTouched = true
    if (userData.name.length === 0) {
      formErrors['name'] = true
    }
    if (userData.surname.length === 0) {
      formErrors['surname'] = true
    }
    if (userData.phone.length === 0) {
      formErrors['phone'] = true
    }
    if (!validateEmail(userData.email)) {
      formErrors['email'] = true
      formTouched = false
    }
    this.setState({ formErrors, userData, formTouched })
    return Object.keys(formErrors).length === 0
  }

  hasError = (field) => {
    return this.state.formErrors[field]
  }

  hasRevoluvipFieldError = (field) => {
    return this.state.revoluVipFormErrors[field]
  }

  handleFormInput = (evt) => {
    const formErrors = this.state.formErrors
    formErrors[evt.target.name] = null
    const userData = this.state.userData
    userData[evt.target.name] = evt.target.value
    this.setState({
      formTouched: true,
      formErrors,
      userData
    })
  }

  handleRevoluVipFormInput = (evt) => {
    const formErrors = this.state.revoluVipFormErrors
    formErrors[evt.target.name] = null
    const revoluvip = this.state.revoluvip
    revoluvip[evt.target.name] = evt.target.value
    this.setState({
      revoluVipFormErrors: formErrors,
      revoluvip
    })
  }

  applyRevoluvip = () => {
    const formErrors = {}
    const revoluvip = this.state.revoluvip
    if (!validateEmail(revoluvip.email)) {
      formErrors['email'] = true
    }
    if (revoluvip.password.length === 0) {
      formErrors['password'] = true
    }
    this.setState({ revoluVipFormErrors: formErrors, revoluvip })
    if (Object.keys(formErrors).length === 0) {
      this.dispatch(revoluvipActions.apply(this.state.revoluvip))
    }
  }

  render () {
    const revoluvip = this.props.revoluvip
    const shouldShowDetails = this.state.shouldShowDetails
    const order = this.props.order
    let redirecting = false
    if (order.data) {
      if (order.data.finishAt) {
        window.location = order.data.finishAt
        redirecting = true
      } else {
        return (<Redirect to='/order/payment' />)
      }
    }
    const cart = this.props.cart
    const currencies = this.props.currencies
    if (!cart.item) {
      return (<Redirect to='/order/operators' />)
    }
    const item = cart.item
    const receiveCurrency = currencies.data[item.product.receiveCurrencyId]
    const sendCurrency = currencies.data[item.product.sendCurrencyId]
    const sendValueInCu = round((item.sendValue / sendCurrency.rate))
    let hasTax = false
    let taxAmount = null
    let taxName = null
    let taxRate = null
    if (item.product.taxRate) {
      hasTax = true
      taxRate = item.product.taxRate
      taxName = item.product.taxName
      if (item.product.taxType.toLowerCase() === 'exclusive') {
        taxAmount = round(item.receiveValue - item.receiveValue / (1 + taxRate / 100))
      } else if (item.product.taxType.toLowerCase() === 'inclusive') {
        taxAmount = round(item.receiveValue * taxRate / 100)
      } else {
        hasTax = false
      }
    }
    return (
      <NamespacesConsumer ns={['checkout']}>
        {t => (
          <React.Fragment>
            <Modal open={order.error} onClose={() => {
              this.dispatch(orderActions.clearError())
            }}>
              <div className={style['modal-body']}>
                <h2>{t('Order could not be placed')}</h2>
                <p>
                  <Trans i18nKey='order-place-error'>
                    We could not place the order. This could be due to unavailability of the operator.
                    Make sure the entered phone number is correct and belongs to the operator.
                    If everything seems correct please try again later or contact our support team.
                  </Trans>
                </p>
              </div>
            </Modal>
            <div className={style.wrapper}>
              <div className={style.content}>
                <h1>{t('Order summary')}</h1>
                <h2>{t('Check if everything is correct and proceed to payment')}</h2>
                <div className={style.box}>
                  <div className={style.buttons}>
                    <button onClick={() => {
                      this.dispatch(cartActions.remove())
                      this.history.push('/order/products')
                    }}>{t('Modify')}</button>
                  </div>
                  <span className={style.label}>{t('Beneficiary')}</span>
                  <span className={style.value}>
                    {`+${item.beneficiary.country.prefix} ${item.beneficiary.phone}`}
                  </span>
                  <div className={style.selection}>
                    <div className={style['item-wrapper']}>
                      <span className={style.label}>{t('Operator')}</span>
                      <Operator operator={item.operator} />
                    </div>
                    <div className={style['item-wrapper']}>
                      <span className={style.label}>{t('Product')}</span>
                      <Product
                        product={item.product}
                        selected={false}
                        receiveValue={item.receiveValue}
                        shouldShowDetails={shouldShowDetails}
                        showDetails={() => {
                          this.setState({ shouldShowDetails: true })
                        }}
                        closeDetails={() => {
                          this.setState({ shouldShowDetails: false })
                        }}
                        currencies={currencies.data}
                      />
                    </div>
                  </div>
                  <div className={style.details}>
                    <div className={style.detail}>
                      <span>{t('Receive value')}</span>
                      <div className={style.currency}>
                        <CurrencyFormat
                          value={item.receiveValue}
                          currencyIso={receiveCurrency.iso} />
                      </div>
                    </div>
                    {hasTax &&
                      <React.Fragment>
                        <div className={style.detail}>
                          <span>{`${taxName} ${taxRate}%`}</span>
                          <div className={style.currency}>
                            <CurrencyFormat
                              value={taxAmount}
                              currencyIso={receiveCurrency.iso} />
                          </div>
                        </div>
                        <div className={style.detail}>
                          <span>{t('Net Receive value')}</span>
                          <div className={style.currency}>
                            <CurrencyFormat
                              value={item.receiveValue - taxAmount}
                              currencyIso={receiveCurrency.iso} />
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    <div className={classNames(style.detail, style.total)}>
                      <span>{t('Topup Amount')}</span>
                      <div className={style.currency}>
                        <CurrencyFormat value={sendValueInCu} currencyIso={'₡CU'} />
                      </div>
                    </div>
                    {this.state.paymentMethod === 'REVOLUPAY' && this.props.revoluvip.reward === 0 &&
                      <React.Fragment>
                        <div className={classNames(style.detail, style.rewards)}>
                          <span>{t('REWARDS')} *</span>
                          <div className={style.currency}>
                            <CurrencyFormat value={sendValueInCu * this.props.config.data.revolupayReward / 100} currencyIso={'₡CU'} />
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    {this.state.paymentMethod === 'REVOLUPAY' && this.props.revoluvip.reward > 0 &&
                      <React.Fragment>
                        <div className={classNames(style.detail, style.rewards)}>
                          <span>{t('REWARDS')}</span>
                          <div className={style.currency}>
                            <CurrencyFormat value={sendValueInCu * this.props.revoluvip.reward / 100} currencyIso={'₡CU'} />
                          </div>
                        </div>
                      </React.Fragment>
                    }
                  </div>
                </div>
                <div className={style.payment}>
                  {/* <h3>{t('PICK_PAYMENT_METHOD')}</h3>
                  <div className={style.methods}>
                    <div
                      className={classNames(
                        style.method,
                        this.state.paymentMethod === 'REVOLUPAY' ? style.selected : null
                      )}
                      onClick={() => {
                        this.setState({ paymentMethod: 'REVOLUPAY' })
                      }}
                    >
                      <img src={revolupayLogoImg} />
                    </div>
                    <div
                      className={classNames(
                        style.method,
                        this.state.paymentMethod === 'PAYLANDS' ? style.selected : null
                      )}
                      onClick={() => {
                        this.setState({ paymentMethod: 'PAYLANDS' })
                      }}
                    >
                      <img src={creditCardImg} />
                    </div>
                  </div> */}
                  {this.state.paymentMethod === 'REVOLUPAY' &&
                    <div className={style.userData}>
                      <div className={style.revoluvipLogo}>
                        <img src={revoluvipLogoImg} />
                      </div>
                      <span className={style.revoluvipDisclosure}>{t('REVOLUVIP_DISCLOSURE')}</span>
                      <div className={style.form}>
                        <div
                          className={classNames(
                            style.controlGroup, this.hasRevoluvipFieldError('email') ? style.error : null
                          )}>
                          <label htmlFor='email'>{t('EMAIL')}</label>
                          <input
                            type='text'
                            name='email'
                            id='revoluvipEmail'
                            disabled={revoluvip.reward > 0}
                            onChange={this.handleRevoluVipFormInput}
                            value={this.state.revoluvip.email}
                          />
                          {this.hasRevoluvipFieldError('email') &&
                            <span>{t('REVOLUVIP_EMAIL_ERROR')}</span>
                          }
                        </div>
                        <div
                          className={classNames(
                            style.controlGroup, this.hasRevoluvipFieldError('password') ? style.error : null
                          )}>
                          <label htmlFor='password'>{t('PASSWORD')}</label>
                          <input
                            type='password'
                            name='password'
                            id='revoluvipPassword'
                            disabled={revoluvip.reward > 0}
                            onChange={this.handleRevoluVipFormInput}
                            value={this.state.revoluvip.password}
                          />
                          {this.hasRevoluvipFieldError('password') &&
                            <span>{t('REVOLUVIP_PASSWORD_ERROR')}</span>
                          }
                        </div>
                        {revoluvip.error &&
                          <div className={style.revoluvipError}>
                            <span>{t('REVOLUVIP_APPLY_ERROR')}</span>
                          </div>
                        }
                        {revoluvip.loading &&
                          <div className={style.spinner}>
                            <Spinner name='ball-scale-multiple' fadeIn='none' />
                          </div>
                        }
                        <div className={style.button}>
                          <button
                            onClick={this.applyRevoluvip}
                            disabled={revoluvip.loading || revoluvip.reward > 0}
                          >
                            {revoluvip.reward > 0 ? t('REVOLUVIP_APPLIED') : t('APPLY_REVOLUVIP')}
                          </button>
                        </div>
                      </div>

                    </div>
                  }
                  {this.state.paymentMethod === 'PAYLANDS' &&
                    <div className={style.userData}>
                      <h3>{t('USERDATA_TITLE')}</h3>
                      <span>{t('USERDATA_DISCLOSURE')}</span>
                      <div className={style.form}>
                        <div
                          className={classNames(
                            style.controlGroup, this.hasError('name') ? style.error : null
                          )}>
                          <label htmlFor='name'>{t('USER_NAME')}</label>
                          <input
                            type='text'
                            name='name'
                            id='name'
                            autoComplete='given-name'
                            onChange={this.handleFormInput}
                            value={this.state.userData.name}
                          />
                          {this.hasError('name') &&
                            <span>{t('USER_NAME_ERROR')}</span>
                          }
                        </div>
                        <div
                          className={classNames(
                            style.controlGroup, this.hasError('surname') ? style.error : null
                          )}>
                          <label htmlFor='surname'>{t('USER_LASTNAME')}</label>
                          <input
                            type='text'
                            name='surname'
                            id='surname'
                            autoComplete='family-name'
                            onChange={this.handleFormInput}
                            value={this.state.userData.surname}
                          />
                          {this.hasError('surname') &&
                            <span>{t('USER_SURNAME_ERROR')}</span>
                          }
                        </div>
                        <div
                          className={classNames(
                            style.controlGroup, this.hasError('phone') ? style.error : null
                          )}>
                          <label htmlFor='phone'>{t('USER_PHONE')}</label>
                          <input
                            type='text'
                            name='phone'
                            id='phone'
                            autoComplete='tel'
                            onChange={this.handleFormInput}
                            value={this.state.userData.phone}
                          />
                          {this.hasError('phone') &&
                            <span>{t('USER_PHONE_ERROR')}</span>
                          }
                        </div>
                        <div
                          className={classNames(
                            style.controlGroup, this.hasError('email') ? style.error : null
                          )}>
                          <label htmlFor='email'>{t('USER_EMAIL')}</label>
                          <input
                            type='email'
                            name='email'
                            id='email'
                            autoComplete='email'
                            onChange={this.handleFormInput}
                            value={this.state.userData.email}
                          />
                          {this.hasError('email') &&
                            <span>{t('USER_EMAIL_ERROR')}</span>
                          }
                        </div>

                      </div>

                    </div>
                  }
                </div>
                <div className={style.proceed}>
                  {(order.loading || redirecting) &&
                    <div className={style.spinner}>
                      <Spinner name='ball-scale-multiple' fadeIn='none' />
                    </div>
                  }
                  <button
                    onClick={this.placeOrder}
                    disabled={
                      order.loading ||
                      this.state.paymentMethod === null ||
                      (this.state.paymentMethod === 'PAYLANDS' && !this.state.formTouched) ||
                      redirecting
                    }
                  >
                    {t('Place order')}
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({
  currencies: state.currencies,
  cart: state.cart,
  order: state.order,
  revoluvip: state.revoluvip,
  config: state.config
}))(withI18n()(withRouter(Checkout)))
