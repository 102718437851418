export const FETCH_PRODUCTS = '@revolucharge/FETCH_PRODUCTS'
export const PUT_PRODUCTS = '@revolucharge/PUT_PRODUCTS'
export default ({
  fetch: ({ operator }) => ({
    type: FETCH_PRODUCTS,
    operator
  }),
  put: (response) => ({
    type: PUT_PRODUCTS,
    products: response.products,
    promotions: response.promos,
    provider: response.provider
  })
})
