import { PLACE_ORDER, PUT_ORDER, CLEAR_ORDER, PLACE_ORDER_ERROR, CLEAR_ORDER_ERROR } from 'actions/order'
export default (
  state = {
    loading: false,
    error: false,
    data: null
  },
  action
) => {
  switch (action.type) {
    case PLACE_ORDER:
      return Object.assign({}, state, { loading: true })
    case PUT_ORDER:
      return Object.assign(
        {}, state, { loading: false, error: false, data: action.order }
      )
    case CLEAR_ORDER:
      return Object.assign(
        {},
        state,
        { data: null }
      )
    case PLACE_ORDER_ERROR:
      return Object.assign(
        {},
        state,
        { loading: false, error: true }
      )
    case CLEAR_ORDER_ERROR:
      return Object.assign(
        {},
        state,
        { error: false }
      )
    default:
      return state
  }
}
