import { call, put, takeLatest } from 'redux-saga/effects'
import shoppingCartActions, { VERIFY_SELECTION } from 'actions/shoppingCart'

export default ({ api }) => {
  function * verify (action) {
    try {
      const verification = yield call(
        api.verifyTransfer, {
          productId: action.selection.product.id,
          receiveValue: action.selection.receiveValue,
          phone: action.selection.beneficiary.phone
        }
      )
      yield put(
        shoppingCartActions.add({
          product: action.selection.product,
          beneficiary: action.selection.beneficiary,
          operator: action.selection.operator,
          receiveValue: verification.receiveValue,
          sendValue: verification.sendValue
        })
      )
    } catch (error) {
      console.error(error)
      yield put(shoppingCartActions.error(error))
    }
  }

  return function * watch () {
    yield takeLatest(VERIFY_SELECTION, verify)
  }
}
