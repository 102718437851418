export const VERIFY_SELECTION = '@revolucharge/VERIFY_SELECTION'
export const ADD_ITEM = '@revolucharge/ADD_ITEM'
export const REMOVE_ITEM = '@revolucharge/REMOVE_ITEM'
export const CLEAR_VERIFICATION_ERROR = '@revolucharge/CLEAR_VERIFICATION_ERROR'
export const VERIFICATION_ERROR = '@revolucharge/VERIFICATION_ERROR'
export default ({
  verify: ({ product, operator, receiveValue, beneficiary }) => ({
    type: VERIFY_SELECTION,
    selection: { product, operator, receiveValue, beneficiary }
  }),
  add: ({ product, operator, sendValue, receiveValue, beneficiary }) => ({
    type: ADD_ITEM,
    item: { product, operator, sendValue, receiveValue, beneficiary }
  }),
  remove: () => ({
    type: REMOVE_ITEM
  }),
  error: (e) => ({
    type: VERIFICATION_ERROR,
    error: e
  }),
  clearVerificationError: () => ({
    type: CLEAR_VERIFICATION_ERROR
  })
})
