import React from 'react'
import style from './Product.scss'
import Searchbar from 'components/shared/Searchbar'
import Product from './Item'
import Operator from 'components/operator/Item'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { NamespacesConsumer, Trans, withI18n } from 'react-i18next'
import classNames from 'classnames'
import cartActions from 'actions/shoppingCart'
import revoluvipActions from 'actions/revoluvip'
import orderActions from 'actions/order'
import Modal from 'react-responsive-modal'
import Spinner from 'react-spinkit'
import moment from 'moment'
import { Set } from 'core-js'

const uniqBy = (a, key) => {
  var seen = new Set()
  return a.filter(item => {
    const k = key(item)
    return seen.has(k) ? false : seen.add(k)
  })
}
const getRoundStep = (product) => {
  let roundToNextMultipleOf = 5
  if (product.maxReceiveValue >= 500) {
    roundToNextMultipleOf = 10
  }
  if (product.maxReceiveValue >= 5000) {
    roundToNextMultipleOf = 500
  }
  if (product.maxReceiveValue >= 10000) {
    roundToNextMultipleOf = 1000
  }
  return roundToNextMultipleOf
}
const getRangedValues = (product, factor) => {
  const roundToNextMultipleOf = getRoundStep(product)
  let receiveValue = Math.ceil(
    ((product.maxReceiveValue - product.minReceiveValue) * factor + product.minReceiveValue) / roundToNextMultipleOf
  ) * roundToNextMultipleOf
  return {
    receiveValue: receiveValue,
    sendValue: receiveValue * product.maxSendValue / product.maxReceiveValue
  }
}

const getValueForReceiveValue = (product, receiveValue) => {
  return {
    receiveValue: receiveValue,
    sendValue: receiveValue * product.maxSendValue / product.maxReceiveValue
  }
}

const getMinValue = (product) => {
  const roundToNextMultipleOf = getRoundStep(product)
  let receiveValue = Math.ceil(
    product.minReceiveValue / roundToNextMultipleOf
  ) * roundToNextMultipleOf
  return {
    receiveValue: receiveValue,
    sendValue: receiveValue * product.maxSendValue / product.maxReceiveValue
  }
}
const getMaxValue = (product) => {
  const roundToNextMultipleOf = getRoundStep(product)
  let receiveValue = Math.floor(
    product.maxReceiveValue / roundToNextMultipleOf
  ) * roundToNextMultipleOf
  return {
    receiveValue: receiveValue,
    sendValue: receiveValue * product.maxSendValue / product.maxReceiveValue
  }
}

class ProductSelection extends React.Component {
  constructor ({ dispatch, i18n }) {
    super()
    this.dispatch = dispatch
    this.state = { selection: null, details: null }
    this.i18n = i18n
  }

  componentWillMount () {
    this.dispatch(orderActions.clear())
    this.dispatch(revoluvipActions.clear())
    this.dispatch(cartActions.remove())
  }

  buildProducts = () => {
    const products = this.props.products
    const currencies = this.props.currencies
    const selection = this.state.selection
    const details = this.state.details
    const productItems = []
    let key = 0
    for (const product of products.data.products) {
      let values = []
      if (product.minReceiveValue === product.maxReceiveValue) {
        values.push({ receiveValue: product.minReceiveValue, sendValue: product.minSendValue })
      } else {
        if (product.reference === 'CU_CU_TopUp') {
          values.push(getValueForReceiveValue(product, 340))
          values.push(getValueForReceiveValue(product, 500))
          values.push(getValueForReceiveValue(product, 568))
          values.push(getValueForReceiveValue(product, 681))
          values.push(getValueForReceiveValue(product, 795))
          values.push(getValueForReceiveValue(product, 909))
          values.push(getValueForReceiveValue(product, 1022))
          values.push(getValueForReceiveValue(product, 1136))
        } else {
          values.push(getMinValue(product))
          if (product.maxSendValue > 40) {
            values.push(getRangedValues(product, 1 / 8))
            values.push(getRangedValues(product, 2 / 8))
            values.push(getRangedValues(product, 3 / 8))
            values.push(getRangedValues(product, 4 / 8))
            values.push(getRangedValues(product, 5 / 8))
            values.push(getRangedValues(product, 6 / 8))
          } else {
            values.push(getRangedValues(product, 1 / 6))
            values.push(getRangedValues(product, 2 / 6))
            values.push(getRangedValues(product, 3 / 6))
            values.push(getRangedValues(product, 4 / 6))
          }
          values.push(getMaxValue(product))
        }
        values = values.filter(
          value => (
            value.receiveValue >= product.minReceiveValue && value.receiveValue <= product.maxReceiveValue &&
            value.sendValue >= product.minSendValue && value.sendValue <= product.maxSendValue
          )
        )
        values = uniqBy(values, (item) => (item.receiveValue))
        values.sort((a, b) => {
          return a.receiveValue - b.receiveValue
        })
      }
      for (const value of values) {
        productItems.push(
          <Product
            key={key++}
            product={product}
            selected={
              selection &&
              product.id === selection.product.id &&
              selection.receiveValue === value.receiveValue
            }
            receiveValue={value.receiveValue}
            sendValue={value.sendValue}
            currencies={currencies.data}
            shouldShowDetails={
              details &&
              product.id === details.product.id &&
              details.receiveValue === value.receiveValue
            }
            showDetails={() => {
              this.setState({ details: { product, receiveValue: value.receiveValue } })
            }}
            closeDetails={() => {
              this.setState({ details: null })
            }}
            onClick={() => {
              this.setState({ selection: { product, receiveValue: value.receiveValue } })
            }} />
        )
      }
    }
    return productItems
  }

  buildPromotions = (t) => {
    const filteredPromos = this.props.products.data.promotions.filter(promo => {
      const content = JSON.parse(promo.content)
      if (this.props.products.data.provider.impl === 'DING') {
        return moment().isBetween(moment(content.startUtc), moment(content.endUtc))
      } else {
        return parseInt(content.running) && moment().isBetween(moment(content.start), moment(content.end))
      }
    })
    if (filteredPromos.length === 0) {
      return (
        <span>{t('NO_ACTIVE_PROMOS')}</span>
      )
    }
    return filteredPromos.map(promo => {
      const content = JSON.parse(promo.content)
      if (this.props.products.data.provider.impl === 'DING') {
        const locale = this.i18n.language
        const filteredDescriptions = content.descriptions.filter((e) => e.languageCode === locale)
        let description = null
        if (filteredDescriptions.length > 0) {
          description = filteredDescriptions[0]
        } else {
          description = content.descriptions[0]
        }
        return (
          <div key={promo.id} className={style.promo}>
            <span className={style.title}>{description.headline}</span>
            <span className={style.title}>{t('TERMSCONDITIONS')}</span>
            <p className={style.content}>{description.termsAndConditions}</p>
            <span className={style.title}>{t('DURATION')}</span>
            <p className={style.content}>{`${new Date(content.startUtc).toLocaleDateString()} - ${new Date(content.endUtc).toLocaleDateString()}`}</p>
            <span className={style.title}>{t('VALIDITY')}</span>
            <p>{description.bonusValidity}</p>
          </div>
        )
      } else {
        return (
          <div key={promo.id} className={style.promo}>
            <span className={style.title}>{content.title}</span>
            <span className={style.title}>{t('TERMSCONDITIONS')}</span>
            <p className={style.content}>{content.conditions}</p>
            <span className={style.title}>{t('DURATION')}</span>
            <p className={style.content}>{`${new Date(content.start).toLocaleDateString()} - ${new Date(content.end).toLocaleDateString()}`}</p>
          </div>
        )
      }
    })
  }

  render () {
    const products = this.props.products
    const operators = this.props.operators
    const countries = this.props.countries
    const cart = this.props.cart
    if (products.operator === null) {
      return (
        <Redirect to='/order/operators' />
      )
    }
    if (cart.item) {
      return (
        <Redirect to='/order/checkout' />
      )
    }
    return (
      <NamespacesConsumer ns={['common']}>
        {t => (
          <React.Fragment>
            <Modal open={cart.error} onClose={() => {
              this.dispatch(cartActions.clearVerificationError())
            }}>
              <div className={style['modal-body']}>
                <h2>{t('Operator unavailable')}</h2>
                <p>
                  <Trans i18nKey='product-selection-error'>
                    The operator is currently not accepting top-ups for the provided phone number.
                    Please verify that the provided phone number is correct and belongs to this operator.
                    If it seems correct, please try again later.
                  </Trans>
                </p>
              </div>
            </Modal>
            <div className={style.wrapper}>
              <div className={style.content}>
                <h1>{t('Enter the phone number you want to top-up')}</h1>
                <Searchbar
                  currentBeneficiary={operators.beneficiary}
                  countries={countries.data}
                  disabled
                />
                <h1>{t('Selected operator')}</h1>
                <Operator operator={products.operator} />
                <Link to='/order/operators' className={style.change}>{t('Change')}</Link>
                <h1>{t('PROMOTIONS')}</h1>
                {products.data.promotions &&
                  <div className={style.promos}>
                    {this.buildPromotions(t)}
                  </div>
                }
                {products.data.products &&
                  <React.Fragment>
                    <h1>{t('Select product')}</h1>
                    <div className={style.results}>
                      {this.buildProducts()}
                    </div>
                  </React.Fragment>
                }

                {cart.loading &&
                  <div className={style.spinner}>
                    <Spinner name='ball-scale-multiple' fadeIn='none' />
                  </div>
                }
                <div className={style.button}>
                  <button
                    disabled={this.state.selection === null || cart.loading}
                    className={classNames({ [style.disabled]: this.state.selection === null })}
                    onClick={() => {
                      this.dispatch(cartActions.verify({
                        product: this.state.selection.product,
                        receiveValue: this.state.selection.receiveValue,
                        beneficiary: operators.beneficiary,
                        operator: products.operator
                      }))
                    }}
                  >
                    {t('Proceed to checkout')}
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({
  currencies: state.currencies,
  countries: state.countries,
  operators: state.operators,
  products: state.products,
  cart: state.cart
}))(withI18n()(ProductSelection))
