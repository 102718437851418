import { call, put, takeLatest } from 'redux-saga/effects'
import currencyActions, { FETCH_CURRENCIES } from 'actions/currencies'

export default ({ api }) => {
  function * fetchCurrencies () {
    try {
      const currencies = yield call(api.getCurrencies)
      yield put(currencyActions.put(currencies))
    } catch (error) {
      console.error(error)
      yield put(currencyActions.put([]))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH_CURRENCIES, fetchCurrencies)
  }
}
